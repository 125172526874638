/*                                                         */
import { defineEvent } from "@otto-ec/global-resources/nexus";
import type { AsyncNamespaceInput, FunctionImplementation } from "@otto-ec/global-resources/nexus";
import { get } from "svelte/store";
/*                                                         */
import { delegate } from "@otto-ec/global-resources/event";
import * as shared from "./SheetV1.shared";
import type {
  SheetV1Back,
  SheetV1Close,
  SheetV1GetActions,
  SheetV1GetContent,
  SheetV1GetHeader,
  SheetV1GetOpenInstance,
  SheetV1Open,
} from "./index.js";

/*                                                       */
/*                                                               */
const DATA_SHEET_ID = "data-oc-sheet-id";

export const open = function open(sheetID) {
  shared.openSheetID.set(sheetID);
} satisfies FunctionImplementation<SheetV1Open>;

export const close = function close() {
  shared.openSheetID.set(null);
} satisfies FunctionImplementation<SheetV1Close>;

export const back = shared.goBack satisfies FunctionImplementation<SheetV1Back>;

export const getOpenSheet = function getOpenSheetInstance() {
  return get(shared.openSheet);
} satisfies FunctionImplementation<SheetV1GetOpenInstance>;

export const getContent = function getContent() {
  const openSheetElement = getOpenSheet();
  return openSheetElement ? shared.getContent(openSheetElement) : null;
} satisfies FunctionImplementation<SheetV1GetContent>;

export const getHeader = function getHeader() {
  const openSheetElement = getOpenSheet();
  return openSheetElement ? shared.getHeader(openSheetElement) : null;
} satisfies FunctionImplementation<SheetV1GetHeader>;

export const getActions = function getActions() {
  const openSheetElement = get(shared.openSheet);
  return openSheetElement ? shared.getActions(openSheetElement) : null;
} satisfies FunctionImplementation<SheetV1GetActions>;

export const sheetV1: AsyncNamespaceInput<OttoComponentApi["sheetV1"]> = {
  open,
  close,
  back,
  getOpenSheet,
  getContent,
  getHeader,
  getActions,
  openEvent: defineEvent(),
  closeEvent: defineEvent(),
  switchEvent: defineEvent(),
  contentLoadedEvent: defineEvent(),
  contentLoadingErrorEvent: defineEvent(),
};

/**
 *
 *
 */
function handleClick(event: MouseEvent): void {
  const clickedElement = event.target as HTMLElement;
  const sheetID = clickedElement.getAttribute(DATA_SHEET_ID);
  const disabled = clickedElement.getAttribute("disabled");
  if (sheetID && !disabled) {
    open(sheetID);
  }
}

delegate(window.document, "click", `[${DATA_SHEET_ID}]`, handleClick);
